<template>
  <basic-layout :header="campus">
    <div class="bg-white bg-opacity-90">
      <container class="">
        <section class="lg:grid lg:grid-cols-12 py-20">
          <div class="lg:col-span-10 p-8 md:p-20">
            <history-details :campus="campus" :librarians="librarians" :titles="titles" :history="history"></history-details>
          </div>
          <div class="lg:col-span-2 hidden lg:block py-28">
            <history-related-links/>
          </div>
        </section>
      </container>
    </div>
  </basic-layout>
</template>

<script>

import BasicLayout from "../../layouts/BasicLayout";
import Container from "../../layouts/Container";
import HistoryRelatedLinks from "../../widgets/HistoryRelatedLinks";
import HistoryDetails from "../../widgets/HistoryDetails";
export default {
  name: "MainHistory",
  components: {HistoryDetails, HistoryRelatedLinks, Container, BasicLayout},
  data:()=>({
    campus: "Main Campus",
    titles: ['NAME','YEAR','SUPERINTENDENT/PRESIDENT'],
    history: ['The Mindoro State College of Agriculture and Technology Main- Campus Library was established on August 26, 1951 formerly named Mindoro National Agricultural School (MINAS) and on July 10, 1976 as Mindoro College of Agriculture and Technology (MCAT).',
      'The College Library of the MinSCAT Main Campus is located in the heart of the campus. It is manned by a competent and full-pledged librarian with the assistance of two permanent staff and two personnel on job order status who are both experienced in library works and graduates of Library Science.',
      'To upgrade the library services, the Main Campus Library operates now with its new library system, the Follet Destiny Library Manager subscribed to last November 2015 with free 4000 pieces of barcode and barcode reader for easy access of the collection and rigid monitoring of the borrowing books and other materials.',
      'Aside from the library management system, the Main Campus Library has a total collections of 21, 241 titles of 39,949 volumes of different resources like books, articles and video clips. Subscription to e-books has been upgraded from 15 titles to 98 titles. Likewise, DOST-STARBOOKS collection of books, videos and articles was also updated to 11,486 titles last September 2015. Improvement of library facilities and services through the purchase of books and other resources of latest edition both local and international, additional computer equipment and enhancement of internet connection both in the College Library and e-library for various courses offerings are accessible to meet the increasing demands of the clients. In addition, the MinSCAT Main Campus library has established linkages thru exchange of publications and sharing of resources among other libraries in the country.',
      'The following served as librarians from 1956 to the present with their administrators.'],
    librarians:[
      {name: 'Luz C. Domingo',year:'1956',position:'Superintendent',headNames:'Eligio C. Ureta'},
      {name: 'Ambrocio Ornido',year:'1964',position:'Superintendent',headNames:'Crispin E. Cabanilla'},
      {name: 'Pacita Silvestre',year:'1972',position:'Superintendent',headNames:'Antonio U. Ganir'},
      {name: 'Rose de Gracia',year:'1975',position:'Superintendent',headNames:'Camilo P. Silvestre'},
      {name: 'Norma M. Caoli',year:'1976-May 31, 2008',position:'College Presidents',headNames:'Dr. Angelito A. Bacudo, Dr. Jesse T. Zamora'},
      {name: 'Filia Ofilea M. Vito',year:'July 2008-present',position:'College Presidents',headNames:'Dr. Jesse T. Zamora, Dr. Levy B. Arago, Jr.'},
      {name: 'Natividad R. Templanza',year:'September 2018-present',position:'College Presidents',headNames:'Dr. Jesse T. Zamora, Dr. Levy B. Arago, Jr.'},
    ]
  })
}
</script>

<style scoped>

</style>